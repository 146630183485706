<template>
  <div class="lands" v-show="showId === '地块'">
    <img
      class="col-img"
      :src="
        plotForm.photo
          ? uploadImgs + plotForm.photo
          : require('../assets/image/land/bgc.png')
      "
    />
    <p class="col-title">{{ plotForm.title }}</p>
    <div class="col-txt">
      产品：
      <span class="text-val" v-for="(t, i) in plotForm.product_list" :key="i"
        >{{ t.title }}
      </span>
    </div>
    <div class="col-txt">
      占地面积：<span class="text-val"
        >{{ (plotForm.area * 0.0015).toFixed(0) }}亩</span
      >
    </div>
    <div class="col-txt">
      简介：<span class="text-val">{{ plotForm.desc }}</span>
    </div>
  </div>
</template>

<script>
import { getPlotInfoApi } from "@/request/api";
import { mapState } from "vuex";

export default {
  props: {
    showId: {
      type: String,
      default: "",
    },
    plot_id: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      plotForm: {},
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
      farmInfo: (state) => state.user.farmInfo,
    }),
  },
  watch: {
    plot_id: {
      handler(newVal) {
        this.getPlotInfoApi(newVal);
      },
    },
  },
  methods: {
    async getPlotInfoApi(plot_id) {
      const { code, results } = await getPlotInfoApi({
        manor_id: this.farmID,
        plot_id,
      });
      if (code === 0) {
        this.plotForm = results;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.lands {
  width: 282px;
  min-height: 282px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top: 6px solid #125397;
  background-color: #0f243d;
  display: flex;
  padding: 34px 10px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 106px;
  left: 78px;
  z-index: 9;
  transition: all 0.5s;

  .col-img {
    width: 158px;
    height: 105px;
    margin-bottom: 22px;
  }

  .col-title {
    color: #fff;
    margin-bottom: 22px;
    font-size: 16px;
    font-weight: bold;
  }

  .col-txt {
    color: #fff;
    font-size: 16px;
    margin-bottom: 18px;

    .text-val {
      color: #409eff;
    }
  }
}
</style>
